.main .img {
  height: 700px;
  width: 1031px;
  margin-left: 11%;
}
body {
  /* background: rgb(233, 141, 70);
  background: -moz-linear-gradient(
    170deg,
    rgb(242, 155, 88) 10%,
    rgb(197, 181, 74) 50%,
    rgba(165, 231, 77, 1) 90%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(233, 141, 70, 1) 10%,
    rgba(242, 217, 49, 1) 50%,
    rgba(165, 231, 77, 1) 90%
  );
  background: linear-gradient(
    170deg,
    rgba(233, 141, 70, 1) 10%,
    rgba(242, 217, 49, 1) 50%,
    rgba(165, 231, 77, 1) 90%
  );
  filter: progid:DXImageTransform.Micros #EDF4F2oft.gradient(startColorstr="#e98d46",endColorstr="#a5e74d",GradientType=1); */
  background-color: #edf4f2;
}
.main .img2 {
  height: 300px;
  width: 300px;
  border-radius: 50%;

  top: 26%;

  position: absolute;
}
.responsive1 {
  width: 259px;
  float: left;
}
.img {
  width: 259px;
  /* margin-left: 78%; */
  /* float: left; */
}
/* .main {
  background-image: url("../img/main.png");
} */
/* margin-left: 11%;

/* .main.centered .img {
  height: 300px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
